import React, { useEffect, useState } from 'react';
import {
  ListItem,
  Checkbox,
  Tooltip,
  IconButton,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Button,
} from '@material-ui/core';

import { TextField } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

const Package = (props) => {
  const {
    formProps,
    packagesInfoList,
    currencyList,
    classes,
    packageList,
    handleChange,
    errors,
    packageState,
    selectedPackage,
    handleCheckBoxChange,
  } = props && props.data;

  return (
    <>
      {packagesInfoList &&
        packagesInfoList.map((currentPackage, index) => (
          <ListItem className={classes.packageContainer} key={index}>
            <ListItem>
              <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div>
                  <FormControl error={!!errors?.[index]?.package_id}>
                    <InputLabel id='demo-simple-select-label'>
                      Select Course
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={currentPackage?.package_id || ''}
                      onChange={(event) => {
                        handleCheckBoxChange(
                          true,
                          event.target.value,
                          'package_id',
                          index,
                          currentPackage?.package_id
                        );
                      }}
                      className={classes.formControlSelect}
                      style={{ cursor: 'pointer', width: 200 }}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      disabled={currentPackage?.disabled || false}
                    >
                      {packageList
                        ? Object.entries(packageList).map(([key, value]) => {
                            return (
                              (!selectedPackage[key] ||
                                (selectedPackage[key] &&
                                  currentPackage?.package_id == key)) && (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            );
                          })
                        : []}
                    </Select>

                    {errors && errors?.[index]?.package_id ? (
                      <FormHelperText className={classes.error}>
                        {errors[index]?.package_id}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                {currentPackage?.disabled && packageState !== 'update' ? (
                  <>
                    <TextField
                      label='Classes Previously Paid For'
                      variant='outlined'
                      type='number'
                      style={{ width: 200 }}
                      value={currentPackage?.classes_previously_paid_for}
                      onChange={(event) =>
                        handleChange(
                          index,
                          'classes_previously_paid_for',
                          event.target.value
                        )
                      }
                      disabled={currentPackage?.disabled || false}
                    />
                    <TextField
                      label='Previous Number of classes'
                      variant='outlined'
                      type='number'
                      style={{ width: 200 }}
                      value={currentPackage?.previous_no_of_classes}
                      onChange={(event) =>
                        handleChange(
                          index,
                          'previous_no_of_classes',
                          event.target.value
                        )
                      }
                      disabled={currentPackage?.disabled || false}
                    />
                  </>
                ) : null}
              </div>

              <Tooltip title='Delete Package'>
                <IconButton
                  onClick={() => {
                    // handleChange(index, 'package', undefined, 'delete');
                    handleCheckBoxChange(false, currentPackage?.package_id);
                  }}
                  disabled={
                    currentPackage?.disabled || packagesInfoList?.length === 1
                  }
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItem>

            <ListItem className={classes.packageFormContainer}>
              <FormControl error={!!errors?.[index]?.currency}>
                <InputLabel id='demo-simple-select-label'>
                  Select Currency
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentPackage?.currency || ''}
                  onChange={(event) => {
                    handleChange(index, 'currency', event.target.value);
                  }}
                  className={classes.formControlSelect}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {currencyList
                    ? currencyList.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))
                    : []}
                </Select>

                {errors && errors?.[index]?.currency ? (
                  <FormHelperText className={classes.error}>
                    {errors[index].currency}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl error={!!errors?.[index]?.amount}>
                <TextField
                  label='Amount'
                  type='number'
                  variant='outlined'
                  value={currentPackage?.amount}
                  onChange={(event) =>
                    handleChange(index, 'amount', event.target.value)
                  }
                />
                {errors && errors[index]?.amount ? (
                  <FormHelperText className={classes.error}>
                    {errors[index].amount}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl error={!!errors?.[index]?.package_classes_paid_for}>
                <TextField
                  label='Classes Paid For'
                  type='number'
                  variant='outlined'
                  value={currentPackage?.package_classes_paid_for}
                  onChange={(event) =>
                    handleChange(
                      index,
                      'package_classes_paid_for',
                      event.target.value
                    )
                  }
                />
                {errors && errors?.[index]?.package_classes_paid_for ? (
                  <FormHelperText className={classes.error}>
                    {errors[index]?.package_classes_paid_for}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl error={!!errors?.[index]?.package_no_of_classes}>
                <TextField
                  label='Number Of Classes'
                  type='number'
                  variant='outlined'
                  value={currentPackage?.package_no_of_classes}
                  onChange={(event) =>
                    handleChange(
                      index,
                      'package_no_of_classes',
                      event.target.value
                    )
                  }
                />
                {errors && errors?.[index]?.package_no_of_classes ? (
                  <FormHelperText className={classes.error}>
                    {errors[index].package_no_of_classes}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </ListItem>
          </ListItem>
        ))}

      <Button
        type='button'
        color='primary'
        variant='outlined'
        onClick={() => handleChange(undefined, 'package', undefined, 'add')}
        disabled={
          formProps.isSubmitting ||
          !packagesInfoList?.[packagesInfoList?.length - 1]?.package_id ||
          packagesInfoList?.length === Object.keys(packageList)?.length
        }
        style={{ margin: '8px 16px', width: 'fit-content' }}
      >
        <Add />
        {'Add Another Package'}
      </Button>
    </>
  );
};

export default Package;
