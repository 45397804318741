import * as React from 'react';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getIn } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import delve from 'dlv';

export const fieldToTextField = ({ field, form, variant, disabled = false, ...props }) => {
    const { name } = field;
    const { touched, errors, isSubmitting, values, setFieldValue } = form;

    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;

    const myOnChange = (data) => {
        setFieldValue(name, data);
    };

    return {
        ...props,
        ...field,
        // Hack to work around type issue
        // See: https://github.com/Microsoft/TypeScript/issues/28791
        variant,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: isSubmitting || disabled,
        onChange: myOnChange,
        value: delve(values, name, null),
    };
};

export const DateTime = ({ children, ...props }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker {...fieldToTextField(props)} ampm={false} />
    </MuiPickersUtilsProvider>
);
