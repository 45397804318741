import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Grid,
  ListItem,
  Typography,
  Divider,
  Button,
  LinearProgress,
  TextField as MaterialUiTextField,
  CircularProgress,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { SaveRounded, Search } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import MySelect from '../../components/FormikSelect';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import Package from './Package/Package';
import SaveChangesModal from '../../components/SaveChangesModal';
import moment from 'moment';

const initialPackagesInfoList = [
  {
    package_id: '',
    amount: '',
    package_no_of_classes: '',
    package_classes_paid_for: '',
    currency: '',
  },
];

function PackageAssign(props) {
  const [showModal, setShowModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const [userId, setUserId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [packagesInfoList, setPackagesInfoList] = useState(
    initialPackagesInfoList
  );
  const [selectedPackage, setSelectedPackage] = useState({});

  const isNewOrder = useRef(null);
  const initialValues = {
    total_amount: '',
    payment_method: null,
    comment: '',
    comment_new: '',
    comment_old: '',
    currency: null,
    status: null,
    payment_link: '',
    total_amount: '',
    date_of_payment: new Date(),
  };
  const listItemSchema = Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  });

  const schema = Yup.object().shape({
    total_amount: Yup.string().required('Enter the total amount'),
    payment_method: listItemSchema.nullable().required('Select payment method'),
    status: listItemSchema.nullable().required('Select Status'),
    payment_link: Yup.string().required('Enter the payment link'),
    comment: Yup.string().required('Enter comment'),
    currency: listItemSchema.nullable().required('Select Currency'),
  });

  const editSchema = Yup.object().shape({
    total_amount: Yup.string().required('Enter the total amount'),
    comment_new: Yup.string().required('Enter new comment'),
    payment_method: listItemSchema.nullable().required('Select payment method'),
    status: listItemSchema.nullable().required('Select Status'),
    payment_link: Yup.string().required('Enter the payment link'),
    currency: listItemSchema.nullable().required('Select Currency'),
  });

  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      assignPackageContainer: {
        gap: 20,
      },
      formContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 20,
      },
      checkboxField: {
        display: 'flex',
        marginLeft: '16px',
        border: '1px solid black',
        borderRadius: 8,
        padding: '8px 16px',
      },
      checkboxItem: {
        display: 'flex',
        alignItems: 'center',
      },
      boldText: {
        fontWeight: 600,
      },
      packageFormContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: 10,
      },
      formBtnContainer: {
        display: 'flex',
        gap: 20,
        justifyContent: 'flex-end',
        // position: 'sticky',
        // bottom: 0,
        // borderTop: '1px solid #ccc',
        // paddingTop: 16,
        // backgroundColor: '#fff',
        // zIndex: 20,
      },
      formControl: {
        width: '100%',
      },
      formControlSelect: {
        zIndex: 2,
      },
      packageContainer: {
        flexDirection: 'column',
        padding: '16px 0',
        border: '1px solid black',
        marginLeft: 16,
        borderRadius: 8,
        width: 'calc(100% - 16px)',
      },
      userIdBox: {
        display: 'flex',
        gap: 10,
        margin: 16,
      },
      searchBtn: {
        height: '100%',
      },
      error: {
        color: '#f44336',
        fontSize: '12px',
        margin: `0px ${theme.spacing(2)}px`,
      },
      menuPaper: {
        maxHeight: 400,
      },
    })
  );

  const classes = useStyles();
  async function getPackage() {
    setBtnLoader(true);

    if (!props.editPage) {
      const wrongUserIds = [];
      let userString = userId;

      const val = Number(userId);
      if (!val) {
        wrongUserIds.push(id);
      }

      if (wrongUserIds?.length) {
        props.enqueueSnackbar('Invalid userids', { variant: 'error' });
        setBtnLoader(false);
        return;
      }
    }

    const arr = [];
    if (props.orderId) arr.push(`order_id=${props.orderId}`);
    if (props.installmentId) arr.push(`installment_id=${props.installmentId}`);

    axios
      .get(
        `/v1/admin/${props.adminId}/student/${
          props?.studentId || userId
        }/order/${arr.length ? `?${arr.join('&')}` : ''}`
      )
      .then((res) => {
        if (res && res.status == 200) {
          const responseData = res.data.data;

          isNewOrder.current = responseData.order_state === 'no_pending_order';
          const selectedPackageObj = {};
          const updatedPackageDetails = responseData?.order_details
            ? responseData.order_details?.package_details.map(
                (packageDetail) => {
                  selectedPackageObj[packageDetail.package_id] = true;
                  return {
                    ...packageDetail,
                    currency: packageDetail.currency
                      ? {
                          id: packageDetail.currency,
                          value: packageDetail.currency,
                        }
                      : '',
                    ...(responseData?.order_state !== 'update'
                      ? {
                          classes_previously_paid_for:
                            packageDetail?.package_classes_paid_for,
                          previous_no_of_classes:
                            packageDetail?.package_no_of_classes,
                          package_classes_paid_for: '',
                          package_no_of_classes: '',
                        }
                      : {}),
                    disabled: true,
                  };
                }
              )
            : initialPackagesInfoList;

          setPackageDetails({
            ...responseData,
            ...(responseData.order_details
              ? {
                  order_details: {
                    ...initialValues,
                    ...responseData.order_details,
                    package_details: updatedPackageDetails,
                    payment_link: responseData.order_details.payment_link || '',
                    status: {
                      label:
                        responseData.status_mapping[
                          responseData.order_details.status
                        ],
                      value: responseData.order_details.status,
                    },
                    payment_method: responseData.order_details?.payment_method
                      ? {
                          label: responseData.order_details.payment_method,
                          value: order_details.payment_method,
                        }
                      : null,
                    currency: responseData.order_details?.currency
                      ? {
                          label: responseData.order_details.currency,
                          value: responseData.order_details.currency,
                        }
                      : null,
                  },
                }
              : { order_details: initialValues }),
          });
          setPackagesInfoList(initialPackagesInfoList);
          setSelectedPackage({});

          if (responseData?.order_details?.date_of_payment)
            setSelectedDate(responseData.order_details.date_of_payment);
          else setSelectedDate(new Date());
        }
        setBtnLoader(false);
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error?.[0] || 'Error',
          {
            variant: 'error',
          }
        );
        setBtnLoader(false);
      });
  }

  const validateBody = () => {
    let errObj = {};
    packagesInfoList.forEach((packageInfo, index) => {
      const packageState = packageDetails?.order_state;
      const validateKeys = [
        !packageInfo.disabled ? 'package_id' : null,
        'amount',
        'package_classes_paid_for',
        'currency',
        'package_no_of_classes',
      ];

      validateKeys.map((validateKey) => {
        if (validateKey && !packageInfo?.[validateKey]) {
          errObj = {
            ...errObj,
            [index]: {
              ...errObj[index],
              [validateKey]: `Please enter ${
                validateKey.charAt(0).toUpperCase() +
                validateKey.replaceAll('_', ' ').slice(1)
              }`,
            },
          };
        }
      });
    });

    setErrors(errObj);
    return Object.keys(errObj).length === 0;
  };

  async function saveData(values, formikActions) {
    try {
      let body = {};

      body = {
        package_details: packagesInfoList.map((p) => ({
          ...(p.package_id ? { package_id: Number(p.package_id) } : {}),
          package_subscription_id: p.package_subscription_id || null,
          classes_paid_for: Number(p.package_classes_paid_for),
          no_of_classes: Number(p.package_no_of_classes),
          amount: Number(p.amount),
          currency: p.currency,
        })),
        status: Number(values.status.value),
        total_amount: values.total_amount,
        comment: !isNewOrder.current ? values.comment_new : values.comment,
        currency: values.currency.value,
        date_of_payment: moment(selectedDate).format('YYYY-MM-DD'),
        payment_link: values.payment_link,
        payment_method: values.payment_method.value,
      };

      if (validateBody()) {
        formikActions.setSubmitting(true);

        formikActions.setFieldValue('error', []);
        const response = await axios.post(
          `/v1/admin/${props.adminId}/student/${
            props.studentId || userId
          }/order/`,
          body
        );
        if (response.status === 200 && response.data) {
          props.enqueueSnackbar('Package Assigned', {
            variant: 'success',
          });
          handleReset(formikActions);
        }
      }
    } catch (error) {
      const errors =
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.error;
      props.enqueueSnackbar(errors || 'error', {
        variant: 'error',
      });
    } finally {
      formikActions.setSubmitting(false);
    }
  }

  const handleChange = (index, key, value, type) => {
    setPackagesInfoList((prevData) => {
      const updatePackagesDetails = [...prevData];

      if (type === 'add') {
        updatePackagesDetails.push({
          package_id: null,
          amount: '',
          package_no_of_classes: '',
          package_classes_paid_for: '',
          currency: '',
        });
        return updatePackagesDetails;
      } else if (type === 'delete') {
        updatePackagesDetails.splice(index, 1);
        return updatePackagesDetails;
      } else {
        updatePackagesDetails[index][key] = value;
        return updatePackagesDetails;
      }
    });
  };

  const pauseOrder = () => {
    const user_id = props?.user?.user_id;
    const data = {
      action: 'pause',
    };
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${props.orderId}/change-status/`,
        data
      )
      .then((response) => {
        if (response && response.status === 200) {
          setShowModal({ pauseOrderModal: false });
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Order Paused Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Could not pause the prder',
          { variant: 'error' }
        );
      });
  };

  const deleteInitialClasses = () => {
    const user_id = props?.user?.user_id;
    const data = {
      no_of_classes: parseInt(
        showModal?.deleteInitialClassesModal?.package_no_of_classes
      ),
    };
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${props.orderId}/delete-classes/`,
        data
      )
      .then((response) => {
        if (response && response.status === 200) {
          setShowModal({ deleteInitialClassesModal: false });

          props.enqueueSnackbar(
            response?.data?.data?.message ||
              'Initial Classes Deleted Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something went wrong',
          { variant: 'error' }
        );
      });
  };

  const handleCheckBoxChange = (
    checkedStatus,
    packageId,
    key,
    index,
    oldCheckedValue
  ) => {
    setSelectedPackage((prevState) => ({
      ...prevState,
      ...(oldCheckedValue ? { [oldCheckedValue]: false } : {}),
      [packageId]: checkedStatus,
    }));

    setPackagesInfoList((prevState) => {
      const old = [...prevState];
      const elementIndex = old.findIndex((pack) => {
        return pack.package_id === packageId;
      });

      if (checkedStatus) {
        if (elementIndex === -1) {
          const alreadySelectedPackage =
            packageDetails?.order_details?.package_details?.filter(
              (pck) => pck.package_id == packageId
            )[0];

          if (alreadySelectedPackage)
            return [...old.filter((o) => o.package_id), alreadySelectedPackage];
          old[index][key] = packageId;
        }
        return old;
      } else {
        if (index === -1) return old;
        return old.length === 1
          ? [{ ...initialPackagesInfoList, package_id: '' }]
          : old.filter((pack) => pack.package_id !== packageId);
      }
    });
  };

  const handleReset = (formProps) => {
    setPackagesInfoList(initialPackagesInfoList);
    setErrors({});
    setSelectedPackage({});
    formProps.setValues(initialValues);
    setSelectedDate(new Date());
    setUserId('');
    formProps.resetForm({ values: initialValues });
  };

  useEffect(() => {
    if (props.editPage) getPackage();
  }, []);

  return (
    <Fragment>
      <SaveChangesModal
        openModal={showModal?.pauseOrderModal}
        closeModal={() => setShowModal({ pauseOrderModal: false })}
        text={'Do you want to pause the order?'}
        onSave={pauseOrder}
        style={{ width: '40vw' }}
      />
      <SaveChangesModal
        openModal={showModal?.deleteInitialClassesModal}
        closeModal={() => setShowModal({ deleteInitialClassesModal: false })}
        text={'Do you want to delete initial classes?'}
        onSave={deleteInitialClasses}
        displayData={
          <MaterialUiTextField
            label='No of Classes'
            variant='outlined'
            value={showModal?.deleteInitialClassesModal?.package_no_of_classes}
            onChange={(event) =>
              setShowModal({
                deleteInitialClassesModal: {
                  package_no_of_classes: event.target.value,
                },
              })
            }
          />
        }
        style={{ width: '40vw' }}
      />

      {props?.editPage ? null : (
        <>
          <ListItem>
            <Typography variant='h4'>Assign Package</Typography>
          </ListItem>
          <Divider />
        </>
      )}

      {props.editPage ? null : (
        <form
          className={classes.userIdBox}
          onSubmit={(e) => {
            e.preventDefault();
            getPackage();
          }}
        >
          <MaterialUiTextField
            id='password'
            label='USER ID'
            variant='outlined'
            value={userId}
            onChange={(event) => setUserId(event.target.value)}
          />
          <Button
            color='primary'
            variant='contained'
            type='submit'
            disabled={btnLoader || !userId}
            className={classes.searchBtn}
          >
            <Search />
          </Button>
        </form>
      )}

      {btnLoader ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : packageDetails ? (
        <Formik
          initialValues={packageDetails.order_details}
          onSubmit={saveData}
          validationSchema={isNewOrder.current ? schema : editSchema}
          render={(formProps) => {
            return (
              <Form>
                <Grid
                  container
                  direction='column'
                  className={classes.assignPackageContainer}
                >
                  {packageDetails.order_details.package_details ? (
                    <div className={classes.checkboxField}>
                      <p className={classes.boldText}>Renew For:</p>
                      {packageDetails.order_details.package_details.map(
                        (packageDetailsData) => (
                          <div
                            className={classes.checkboxItem}
                            key={packageDetailsData.package_id}
                            value={packageDetailsData.package_id}
                          >
                            <Checkbox
                              checked={
                                selectedPackage[
                                  packageDetailsData.package_id
                                ] || false
                              }
                              onChange={() =>
                                handleCheckBoxChange(
                                  event.target.checked,
                                  packageDetailsData.package_id
                                )
                              }
                            />
                            <ListItemText
                              primary={
                                packageDetails.package_list[
                                  packageDetailsData.package_id
                                ]
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  ) : null}

                  <Package
                    data={{
                      formProps,
                      packageState: packageDetails?.order_state,
                      packagesInfoList,
                      currencyList: packageDetails?.currency_list,
                      packageList: packageDetails?.package_list,
                      classes,
                      handleChange,
                      errors,
                      isNewOrder: isNewOrder.current,
                      selectedPackage,
                      handleCheckBoxChange,
                    }}
                  />

                  <ListItem className={classes.formContainer}>
                    <Field
                      name='total_amount'
                      component={TextField}
                      className={classes.formControl}
                      label='Total Amount'
                      variant='outlined'
                    />

                    <div style={{ zIndex: 10 }}>
                      <Field
                        name='currency'
                        selectList={
                          packageDetails?.currency_list
                            ? packageDetails?.currency_list.map((curr) => ({
                                value: curr,
                                label: curr,
                              }))
                            : []
                        }
                        component={MySelect}
                        className={classes.formControl}
                        label='Currency'
                        variant='outlined'
                      />
                      <ErrorMessage
                        className={classes.error}
                        component='div'
                        name='currency'
                      />
                    </div>

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        variant='inline'
                        label='Date of payment'
                        value={selectedDate}
                        className={classes.formControl}
                        onChange={setSelectedDate}
                        dateRangeIcon
                      />
                    </MuiPickersUtilsProvider>

                    <div style={{ zIndex: 10 }}>
                      <Field
                        name='payment_method'
                        selectList={
                          packageDetails?.payment_method_list
                            ? packageDetails.payment_method_list.map(
                                (payMeth) => ({
                                  value: payMeth,
                                  label: payMeth,
                                })
                              )
                            : []
                        }
                        component={MySelect}
                        className={classes.formControl}
                        label='Payment Method'
                        variant='outlined'
                      />
                      <ErrorMessage
                        className={classes.error}
                        component='div'
                        name='payment_method'
                      />
                    </div>

                    <Field
                      name='payment_link'
                      component={TextField}
                      className={classes.formControl}
                      label='Payment Link'
                      variant='outlined'
                    />

                    {!isNewOrder.current ? (
                      <>
                        <Field
                          name='comment_old'
                          component={TextField}
                          className={classes.formControl}
                          label='Comment Old'
                          variant='outlined'
                          disabled={true}
                        />
                        <Field
                          name='comment_new'
                          component={TextField}
                          className={classes.formControl}
                          label='Comment New'
                          variant='outlined'
                        />
                      </>
                    ) : (
                      <Field
                        name='comment'
                        component={TextField}
                        className={classes.formControl}
                        label='Comment'
                        variant='outlined'
                      />
                    )}

                    <div>
                      <Field
                        label='Status'
                        className={classes.formControl}
                        selectList={
                          packageDetails?.status_mapping
                            ? Object.entries(packageDetails.status_mapping).map(
                                ([key, value]) => ({
                                  value: key,
                                  label: value,
                                })
                              )
                            : []
                        }
                        component={MySelect}
                        name='status'
                      />
                      <ErrorMessage
                        className={classes.error}
                        component='div'
                        name='status'
                      />
                    </div>
                  </ListItem>

                  <ListItem>
                    {formProps.isSubmitting ? (
                      <LinearProgress style={{ width: '100%' }} />
                    ) : null}
                  </ListItem>

                  {formProps.values?.error &&
                  formProps.values.error.length > 0 ? (
                    <ListItem>
                      <Typography>{formProps.values.errorName}</Typography>
                      <br />
                      <br />
                      <ul>
                        {formProps.values.error.map((item, index) => (
                          <li key={index} className={classes.error}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </ListItem>
                  ) : null}

                  <ListItem className={classes.formBtnContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      disabled={formProps.isSubmitting}
                    >
                      {props.editPage ? 'UPDATE PACKAGE' : 'ASSIGN PACKAGE'}
                      <SaveRounded className={classes.rightIcon} />
                    </Button>
                    {props?.editPage ? (
                      <>
                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            setShowModal({
                              deleteInitialClassesModal: {
                                package_no_of_classes: '',
                              },
                            })
                          }
                        >
                          Delete Initial Classes
                        </Button>

                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            setShowModal({ pauseOrderModal: true })
                          }
                        >
                          Pause Order
                        </Button>
                      </>
                    ) : (
                      <Button
                        type='button'
                        color='primary'
                        variant='outlined'
                        onClick={() => handleReset(formProps)}
                        disabled={formProps.dirty || formProps.isSubmitting}
                        style={{ marginRight: '8px' }}
                      >
                        {'Reset'}
                      </Button>
                    )}
                  </ListItem>
                </Grid>
              </Form>
            );
          }}
          validateOnChange
        />
      ) : null}
    </Fragment>
  );
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(PackageAssign));
