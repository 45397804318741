import FormHelperText from '@material-ui/core/FormHelperText';
import { withSnackbar } from 'notistack';
import React, { Component, useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import {
  List,
  Grid,
  ListItem,
  InputLabel,
  Modal,
  Icon,
  IconButton,
  FormLabel,
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Select as MultiSelect } from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import { browserHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import * as actions from '../../actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { countryDataBackup } from '../../Utils/country';
import S3UploadButton from '../../components/S3UploadButton';
import moment from 'moment';

const initialValue = {
  child_name: '',
  name: '',
  school: '',
  class_id: '',
  division: '',
  city_name: '',
  mobile_number: '',
  email: '',
  user_img: '',
  role: '',
  lq_child_id: '',
  ac_id: '',
  address_1: '',
  address_2: '',
  city: '',
  pincode: '',
  landmark: '',
  state: '',
  password: '',
  rePassword: '',
  country_code: '+91',
  avatar: null,
  is_academic_counsellor: null,
  admin_role: null,
  gender: null,
  parent_details: {
    parent_id: '',
    name: '',
    email: '',
    mobile_number: '',
    country: null,
    city: null,
    role: '',
    password: '',
    lq_parent_id: '',
    title: 'Mr.',
    description: '',
  },
};

function CreateUserForm(props) {
  const [role, setRole] = useState(
    window.location.href.indexOf('teacher') != -1
      ? 'teacher'
      : window.location.href.indexOf('user') != -1
      ? 'parent'
      : 'admin'
  );
  const [mode, setMode] = useState('');
  const [studentId, setstudentId] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  // const [password, setPassword] = useState(null);
  // const [rePassword, setRePassword] = useState(null);
  const [rmList, setRmList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState(null);
  const [showError, setShowError] = useState(false);
  const packageDataList = [
    { value: 4, label: 'LogiMath' },
    { value: 5, label: 'Science' },
  ];
  const [classList, setClassList] = useState(null);
  const [packageData, setPackageData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [adminRolesList, setAdminRoleList] = useState([]);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const parentDetailsSchema = {
    parent_id: Yup.string('').nullable(),
    // name: Yup.string('').required('This field is compulsory'),
    email: Yup.string('').required('This field is compulsory'),
    mobile_number: Yup.number()
      .typeError('Please enter a valid number')
      .required('This field is compulsory'),
    role: Yup.string(''),
    // password:
    //   mode !== 'update'
    //     ? Yup.string('Invalid Password').required('This field is compulsory')
    //     : Yup.string('Invalid Password'),
  };

  const mainUserSchema = {
    child_name: Yup.string('').required('This field is compulsory'),
    // school: Yup.string('').required('This field is compulsory'),
    class_id: Yup.string('').required('This field is compulsory'),
    divison: Yup.string(''),
    // city_name: Yup.string('').required('This field is compulsory'),
    user_img: Yup.string('Invalid User Image'),
    role: Yup.string('Invalid Role'),
  };

  const schema = Yup.object().shape({
    ...mainUserSchema,
    parent_details: Yup.object().shape(parentDetailsSchema),
  });

  const [ref, setRef] = useState(React.createRef());

  useEffect(() => {
    let studentId =
      props &&
      props.location &&
      props.location.query &&
      props.location.query.userId;
    getRmList();
    getClassDetail();
    if (studentId) {
      getUserDetails(studentId);

      setMode('update');
      setstudentId(studentId);
    }
    if (role == 'admin') {
      fetchRolesData();
    }
    country();
  }, [ref]);

  const classes = styles();
  function handleRoleSection(event) {
    setRole(event.target.value);
  }
  function fetchRolesData() {
    const admin_id = props?.user?.user_id;
    axios
      .get(`/v1/admin/${admin_id}/admins/roles/`)
      .then((res) => {
        if (res?.status && res?.data?.data) {
          const data = res.data.data.data;
          const rolesData = data.map((rolesListDetail) => rolesListDetail.role);
          setAdminRoleList(rolesData);
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }
  function country() {
    let countryData = Object.values(countryDataBackup);
    countryData.map(
      (country, index) =>
        (countryData[index] = {
          value: '+' + country.Va,
          label: '+' + country.Va + '(' + country.name + ')',
        })
    );
    setCountryCodeList(countryData);
  }

  function getRmList() {
    const user_id = props && props.user && props.user.user_id;
    axios.get(`/v1/admin/${user_id}/ac/`).then((res) => {
      if (res.status == 200 && res.data) {
        let temp = [];
        temp =
          res.data &&
          res.data.data.map &&
          res.data.data.map((a) => {
            return {
              value: a.id,
              label: a.name,
            };
          });
        setRmList(temp);
      }
    });
  }

  async function getUserDetails(studentId) {
    const user_id = props && props.user.user_id;
    axios
      .get(`/v1/admin/${user_id}/student/${studentId}/profile/`)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          const data = response.data.data;
          if (data.role == 'parent') {
            setRole('parent');
            let newValues;

            newValues = {
              child_name: data.student_details && data.student_details.name,
              name: '',
              school: data.student_details && data.student_details.school_name,
              class_id: data.student_details && data.student_details.class_id,
              division: data.student_details && data.student_details.division,
              city_name:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.city_name,
              address_1:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.address_1,
              address_2:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.address_2,
              state:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.state,
              pincode:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.pincode,
              landmark:
                data.student_details &&
                data.student_details.address &&
                data.student_details.address.landmark,
              gender: data.student_details && data.student_details.gender,
              mobile_number: data.phone,
              email: data.email,
              country_code: data.country_code,
              user_img: '',
              role: data.role,
              password: '',
              rePassword: '',
              lq_child_id:
                data.student_details && data.student_details.lq_child_id,
              ac_id:
                data.student_details &&
                data.student_details.ac &&
                data.student_details.ac.id,
              parent_details: {
                parent_id: '',
                name: data.parent_name,
                email: data.email,
                mobile_number: data.phone,
                country: null,
                city: data.profile_details && data.profile_details.city_name,
                role: data.role,
                password: '',
                lq_parent_id: data.lq_parent_id,
              },
            };
            console.log('datauser', newValues, ref);
            if (ref && ref.current) {
              ref.current.setValues(newValues);
            }
            return Promise.resolve();
          }
        }
      })
      .catch((error) => {
        props.enqueueSnackbar('User Details Could not be fetched', {
          variant: 'warning',
        });
        props.hideTopLoader();
        console.log(error);
      });
  }
  function getClassDetail() {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        setClassList(data);
      }
    });
  }
  function submitForm(formikProps, formikActions) {
    let api_data = {
      email: formikProps.parent_details && formikProps.parent_details.email,
      phone:
        formikProps.parent_details && formikProps.parent_details.mobile_number,
      role: role,
      country_code: formikProps.country_code,
      password:
        (formikProps.parent_details && formikProps.parent_details.password) ||
        null,
      name: formikProps.parent_details && formikProps.parent_details.name,
      city_name:
        role === 'parent'
          ? null
          : formikProps.city_name && formikProps.city_name,

      student_details: {
        name: formikProps.child_name,
        school_name: formikProps.school || null,
        class_id: formikProps.class_id,
        avatar: null,
        gender: formikProps.gender || null,
      },
    };
    if (mode == 'update') {
      api_data = {
        email: formikProps.parent_details && formikProps.parent_details.email,
        phone:
          formikProps.parent_details &&
          formikProps.parent_details.mobile_number,
        role: role,
        parent_name:
          formikProps.parent_details && formikProps.parent_details.name,
        city_id: 1,
        address: {
          city_name: formikProps.city_name || null,
          address_1: formikProps.address_1 || null,
          address_2: formikProps.address_2 || null,
          state: formikProps.state || null,
          pincode: formikProps.pincode || null,
          landmark: formikProps.landmark || null,
        },
        student_name: formikProps.child_name,
        school_name: formikProps.school,
        division: formikProps.division,
        class_id: formikProps.class_id,
        ac_id: formikProps.ac_id,
        lq_parent_id:
          formikProps.parent_details && formikProps.parent_details.lq_parent_id,
        lq_child_id: formikProps.lq_child_id,
        country_code: formikProps.country_code,
        gender: formikProps.gender || null,
      };
    }
    if (role == 'teacher' || role == 'affiliate' || role == 'admin') {
      api_data = {
        email: formikProps.parent_details && formikProps.parent_details.email,
        phone:
          formikProps.parent_details &&
          formikProps.parent_details.mobile_number,
        role:
          role == 'admin'
            ? formikProps.admin_role.toLowerCase().replaceAll(' ', '_')
            : role,
        password:
          formikProps.parent_details && formikProps.parent_details.password,
        name:
          role == 'teacher'
            ? (formikProps.parent_details?.title).concat(
                ' ',
                formikProps.parent_details?.name
              )
            : formikProps.parent_details?.name,
        city_name: formikProps.city_name,
        country_code: formikProps.country_code,
        ...(role == 'admin' && {
          is_academic_counsellor: formikProps.is_academic_counsellor,
        }),
        ...(role == 'teacher' && {
          teacher_details: {
            packages: packageData,
            classes: classData,
            avatar: formikProps.avatar,
            description: formikProps.parent_details?.description,
          },
        }),
      };
    }

    const user_id = props && props.user && props.user.user_id;
    let url = `/v1/admin/${user_id}/create-user/`;
    if (mode == 'update') {
      url = `/v1/admin/${user_id}/student/${studentId}/profile/`;
    }
    axios
      .post(url, api_data)
      .then((response) => {
        if (
          (response.status === 200 || response.status === 202) &&
          response.data
        ) {
          if (mode == 'update') {
            props.enqueueSnackbar('User Updated Successfully', {
              variant: 'success',
            });
            getUserDetails(studentId);
            formikActions.setSubmitting(false);
          } else {
            props.enqueueSnackbar(
              response?.data?.data?.message || 'User Created Successfully',
              {
                variant: 'success',
              }
            );
            formikActions.resetForm(initialValue);
            formikActions.setSubmitting(false);
          }
          return Promise.resolve();
        }
        return Promise.reject('Status code not correct');
      })
      .catch((error) => {
        console.log('error', error);
        formikActions.setSubmitting(false);

        if (
          error?.response?.data?.data?.error &&
          error.response.data.data.error[0]
        ) {
          const errors = error.response.data.data.error;
          errors.map((errorDisplay) =>
            props.enqueueSnackbar(errorDisplay || 'error', { variant: 'error' })
          );
        } else {
          props.enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      });
  }
  return (
    <Formik
      initialValues={initialValue}
      onSubmit={submitForm}
      validationSchema={
        role == 'teacher' || role == 'affiliate' || role == 'admin'
          ? null
          : schema
      }
      innerRef={ref}
      validateOnChange
      render={(formProps) => {
        const FormValues = formProps.values;
        const gradeError = formProps.errors.class_id;
        const countryCodeError =
          formProps.errors.parent_details &&
          formProps.errors.parent_details.country_code;

        return (
          <Form className={classes.formBox}>
            <div className={classes.main}>
              {role == 'parent' ? (
                <div className={classes.parentContainer}>
                  <div className={classes.parentFormComponent}>
                    <Grid
                      container
                      direction='row'
                      className={classes.headingBox}
                    >
                      <Typography className={classes.formTitle} variant='h4'>
                        Parent Details
                      </Typography>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setChangePasswordModal(true)}
                        style={{ marginLeft: 16 }}
                      >
                        DISABLE USER
                      </Button>
                    </Grid>
                    <Grid container className={classes.parentContentBox}>
                      <Grid item>
                        <Field
                          name='parent_details.name'
                          type='text'
                          className={classes.formControlParentForm}
                          component={TextField}
                          label='Parent Name'
                          variant='outlined'
                          placeholder='Parent Name'
                          helperText='Please Enter Parent Name'
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name='parent_details.email'
                          type='text'
                          className={classes.formControlParentForm}
                          component={TextField}
                          label='Parent Email'
                          variant='outlined'
                          placeholder='Parent Email'
                          helperText='Please Enter Parent Email'
                        />
                      </Grid>

                      <Grid item>
                        <FormControl
                          variant='outlined'
                          className={classes.grades}
                          error={Boolean(countryCodeError)}
                        >
                          <InputLabel
                            shrink
                            htmlFor='country_code'
                            style={{
                              backgroundColor: '#fafafa',
                              marginLeft: 10,
                              padding: '0px 5px',
                            }}
                          >
                            {'Country Code'}
                          </InputLabel>

                          <Field
                            name='country_code'
                            component={Select}
                            className={classes.formControlParentForm}
                            label='Class'
                            variant='outlined'
                            helperText='Class'
                          >
                            {countryCodeList &&
                              countryCodeList.map((item, index) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Field>
                          {countryCodeError ? (
                            <FormHelperText>{countryCodeError}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Field
                          name='parent_details.mobile_number'
                          type='text'
                          className={classes.formControlParentForm}
                          component={TextField}
                          label='Mobile Number'
                          variant='outlined'
                          placeholder=' Mobile Number'
                          helperText='Please Enter Mobile Number'
                        />
                      </Grid>

                      {mode !== 'update' ? (
                        <Grid item>
                          <Field
                            name='parent_details.password'
                            type='text'
                            className={classes.formControlParentForm}
                            component={TextField}
                            label='Password'
                            variant='outlined'
                            placeholder='Password'
                            helperText='Please Enter Password'
                          />
                        </Grid>
                      ) : (
                        <>
                          <Grid item>
                            <Field
                              name='parent_details.lq_parent_id'
                              type='text'
                              className={classes.formControlParentForm}
                              component={TextField}
                              label='LQ Parent ID'
                              variant='outlined'
                              disabled
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='lq_child_id'
                              type='text'
                              className={classes.formControlParentForm}
                              component={TextField}
                              label='LQ Child ID'
                              variant='outlined'
                              disabled
                            />
                          </Grid>

                          {changePasswordModal ? (
                            <Modal
                              open={changePasswordModal}
                              onClose={() => {
                                setChangePasswordModal(false);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#fff',
                                  margin: 'auto',
                                  width: 600,
                                  height: 200,
                                  zIndex: 10,
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  left: 0,
                                  position: 'absolute',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 50,
                                    gap: 20,
                                  }}
                                >
                                  <List style={{ fontSize: 20 }}>
                                    Do you want to disable user?
                                  </List>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-evenly',
                                    }}
                                  >
                                    <Button
                                      variant='outlined'
                                      color='primary'
                                      onClick={() =>
                                        setChangePasswordModal(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant='outlined'
                                      color='primary'
                                      onClick={() => {
                                        axios
                                          .post(
                                            `v1/admin/${
                                              props &&
                                              props.user &&
                                              props.user.user_id
                                            }/student/${studentId}/disable/`
                                          )
                                          .then((res) => {
                                            if (
                                              res &&
                                              res.status &&
                                              res.status == 200
                                            ) {
                                              setChangePasswordModal(false);
                                              props.enqueueSnackbar(
                                                (res &&
                                                  res.data &&
                                                  res.data.data &&
                                                  res.data.data.message) ||
                                                  'User disabled Successfully',
                                                { variant: 'success' }
                                              );
                                              // getUserDetails(studentId)
                                              browserHistory.push({
                                                pathname: '/user',
                                                search: '',
                                              });
                                            }
                                          })
                                          .catch((error) => {
                                            props.enqueueSnackbar(
                                              error.response &&
                                                error.response.data &&
                                                error.response.data.data
                                                  .error &&
                                                error.response.data.data.error
                                                  .password &&
                                                error.response.data.data.error
                                                  .password[0],
                                              { variant: 'error' }
                                            );
                                          });
                                      }}
                                    >
                                      DISABLE
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          ) : null}
                        </>
                      )}
                    </Grid>
                  </div>
                  <div className={classes.parentFormComponent}>
                    <Typography className={classes.formTitle} variant='h4'>
                      User Details
                    </Typography>
                    <div className={classes.gridBox}>
                      <Field
                        name='child_name'
                        className={classes.formControlParentForm}
                        type='text'
                        component={TextField}
                        label='Child Name'
                        variant='outlined'
                        placeholder='Child Name'
                        helperText='Please Enter Child Name'
                      />

                      <Field
                        name='school'
                        type='text'
                        className={classes.formControlParentForm}
                        component={TextField}
                        label='School'
                        variant='outlined'
                        placeholder='School'
                        helperText='Please Enter School'
                      />

                      <Grid container className={classes.gridItem}>
                        <Grid item>
                          <FormControl
                            variant='outlined'
                            className={classes.grades}
                            error={Boolean(gradeError)}
                          >
                            <InputLabel
                              shrink
                              htmlFor='class_id'
                              style={{
                                backgroundColor: '#fafafa',
                                padding: '0px 5px',
                              }}
                            >
                              {'Grades'}
                            </InputLabel>

                            <Field
                              name='class_id'
                              component={Select}
                              label='Class'
                              variant='outlined'
                              helperText='Class'
                            >
                              {classList &&
                                classList.map((item, index) => (
                                  <MenuItem value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </Field>
                            {gradeError ? (
                              <FormHelperText>{gradeError}</FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl
                            variant='outlined'
                            className={classes.grades}
                          >
                            <InputLabel
                              shrink
                              htmlFor='ac_id'
                              style={{
                                backgroundColor: '#fafafa',
                                padding: '0px 5px',
                              }}
                            >
                              {'Academic Counsellor'}
                            </InputLabel>

                            <Field
                              name='ac_id'
                              component={Select}
                              label='Class'
                              variant='outlined'
                              helperText='Class'
                            >
                              {rmList &&
                                rmList.map((item, index) => (
                                  <MenuItem value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl className={classes.fullWidth}>
                        <FormLabel>Gender</FormLabel>
                        <RadioGroup
                          className={classes.formControlParentForm}
                          aria-label='Gender'
                          name='gender'
                          value={FormValues.gender}
                          onChange={(event) =>
                            formProps.setFieldValue(
                              'gender',
                              event.target.value
                            )
                          }
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 10,
                          }}
                        >
                          <FormControlLabel
                            value='male'
                            control={<Radio />}
                            label='Male'
                          />
                          <FormControlLabel
                            value='female'
                            control={<Radio />}
                            label='Female'
                          />
                        </RadioGroup>
                      </FormControl>

                      {/* <Grid item>
                            <ListItem>
                              <Field
                                name='division'
                                type='text'
                                component={TextField}
                                label='Division'
                                variant='outlined'
                                placeholder='Division'
                                helperText='Please Enter Division'
                              />
                            </ListItem>
                          </Grid> */}
                      {studentId ? (
                        <>
                          <Field
                            name='address_1'
                            type='text'
                            className={classes.formControlParentForm}
                            component={TextField}
                            label='Address Line 1'
                            variant='outlined'
                            placeholder='Address 1'
                            helperText='Please Enter Address 1'
                          />

                          <Field
                            name='address_2'
                            type='text'
                            className={classes.formControlParentForm}
                            component={TextField}
                            label='Address Line 2'
                            variant='outlined'
                            placeholder='Address 2'
                            helperText='Please Enter Address 2'
                          />

                          <Grid container className={classes.gridItem}>
                            <Field
                              name='city_name'
                              className={classes.formControlParentForm}
                              type='text'
                              component={TextField}
                              label='City'
                              variant='outlined'
                              placeholder='City'
                              helperText='Please Enter City'
                            />
                            <Field
                              name='state'
                              type='text'
                              component={TextField}
                              label='State'
                              className={classes.formControlParentForm}
                              variant='outlined'
                              placeholder='State'
                              helperText='Please Enter State'
                            />
                          </Grid>

                          <Grid container className={classes.gridItem}>
                            <Grid item>
                              <Field
                                name='pincode'
                                className={classes.formControlParentForm}
                                type='text'
                                component={TextField}
                                label='Pincode'
                                variant='outlined'
                                placeholder='Pincode'
                                helperText='Please Enter Pincode'
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                name='landmark'
                                type='text'
                                component={TextField}
                                label='Landmark'
                                className={classes.formControlParentForm}
                                variant='outlined'
                                placeholder='Landmark'
                                helperText='Please Enter Landmark'
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={classes.twoForms}>
                {role == 'teacher' || role == 'affiliate' || role == 'admin' ? (
                  <div className={classes.formComponent}>
                    <List>
                      <Typography variant='h5'>
                        {role[0].toUpperCase() + role.slice(1)} Details
                      </Typography>
                    </List>
                    {role == 'teacher' || role == 'affiliate' ? (
                      <List>
                        <ListItem>
                          <Grid container spacing={4}>
                            <Grid item>
                              <RadioGroup
                                className={classes.formControl}
                                aria-label='Select Role'
                                name='role'
                                value={role}
                                onChange={(event) => handleRoleSection(event)}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 10,
                                }}
                              >
                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                  Role:{' '}
                                </div>
                                <FormControlLabel
                                  value='teacher'
                                  control={<Radio />}
                                  label='Teacher'
                                />
                                <FormControlLabel
                                  value='affiliate'
                                  control={<Radio />}
                                  label='Affiliate'
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    ) : null}
                    <List>
                      <ListItem>
                        <Grid container spacing={4}>
                          <Grid item>
                            <Field
                              name='parent_details.title'
                              component={Select}
                              className={classes.formControl}
                              label='Title'
                              variant='outlined'
                              helperText='Title'
                              style={{
                                minWidth: 50,
                                maxWidth: 75,
                                display:
                                  role == 'teacher' ? 'inline-flex' : 'none',
                              }}
                            >
                              <MenuItem value={'Mr.'}>Mr.</MenuItem>
                              <MenuItem value={'Ms.'}>Ms.</MenuItem>
                            </Field>
                            <Field
                              name='parent_details.name'
                              type='text'
                              component={TextField}
                              className={classes.formControl}
                              label={
                                role[0].toUpperCase() + role.slice(1) + ' Name'
                              }
                              variant='outlined'
                              placeholder={
                                role[0].toUpperCase() + role.slice(1) + ' Name'
                              }
                              helperText={'Please enter ' + role + ' name'}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='parent_details.email'
                              type='text'
                              component={TextField}
                              className={classes.formControl}
                              label={
                                role[0].toUpperCase() + role.slice(1) + ' Email'
                              }
                              variant='outlined'
                              placeholder={
                                role[0].toUpperCase() + role.slice(1) + ' Email'
                              }
                              helperText={'Please enter ' + role + ' email'}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                    {role === 'teacher' && (
                      <List>
                        <ListItem>
                          <Grid container spacing={4}>
                            <Grid item>
                              <FormControl
                                style={{
                                  minWidth: 210,
                                  maxWidth: 400,
                                  marginLeft: 16,
                                  marginRight: 16,
                                }}
                              >
                                <InputLabel id='demo-multiple-checkbox-label'>
                                  Select Package
                                </InputLabel>
                                <MultiSelect
                                  style={{ width: 200 }}
                                  labelId='demo-multiple-checkbox-label'
                                  id='demo-multiple-checkbox'
                                  multiple
                                  value={packageData}
                                  onChange={(event) =>
                                    setPackageData(event.target.value)
                                  }
                                  renderValue={(selected) =>
                                    packageDataList && (
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: 10,
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {packageDataList.map(
                                          (packageList) =>
                                            selected.indexOf(
                                              packageList.value
                                            ) > -1 && (
                                              <div>{packageList.label}</div>
                                            )
                                        )}
                                      </div>
                                    )
                                  }
                                >
                                  {packageDataList.map((packageList) => (
                                    <MenuItem
                                      key={packageList.value}
                                      value={packageList.value}
                                    >
                                      <Checkbox
                                        checked={
                                          packageData
                                            .toString()
                                            .indexOf(packageList.value) > -1
                                        }
                                      />
                                      <ListItemText
                                        primary={packageList.label}
                                      />
                                    </MenuItem>
                                  ))}
                                </MultiSelect>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl
                                style={{
                                  minWidth: 210,
                                  maxWidth: 400,
                                  marginLeft: 16,
                                  marginRight: 16,
                                }}
                              >
                                <InputLabel id='demo-multiple-checkbox-label'>
                                  Select Class
                                </InputLabel>
                                <MultiSelect
                                  style={{ width: 200 }}
                                  labelId='demo-multiple-checkbox-label'
                                  id='demo-multiple-checkbox'
                                  multiple
                                  value={classData}
                                  onChange={(event) =>
                                    setClassData(event.target.value)
                                  }
                                  renderValue={(selected) =>
                                    classList && (
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          gap: 10,
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {classList.map(
                                          (packageList) =>
                                            selected.indexOf(
                                              packageList.value
                                            ) > -1 && (
                                              <div>{packageList.label}</div>
                                            )
                                        )}
                                      </div>
                                    )
                                  }
                                >
                                  {classList &&
                                    classList.map((packageList) => (
                                      <MenuItem
                                        key={packageList.value}
                                        value={packageList.value}
                                      >
                                        <Checkbox
                                          checked={
                                            classData
                                              .toString()
                                              .indexOf(packageList.value) > -1
                                          }
                                        />
                                        <ListItemText
                                          primary={packageList.label}
                                        />
                                      </MenuItem>
                                    ))}
                                </MultiSelect>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    )}

                    <List>
                      <ListItem>
                        <Grid container spacing={4}>
                          <Grid item>
                            <Field
                              name='parent_details.mobile_number'
                              type='text'
                              component={TextField}
                              className={classes.formControl}
                              label='Mobile Number'
                              variant='outlined'
                              placeholder=' Mobile Number'
                              helperText='Please Enter Mobile Number'
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='parent_details.password'
                              type='text'
                              component={TextField}
                              className={classes.formControl}
                              label='Password'
                              variant='outlined'
                              placeholder='Password'
                              helperText='Please Enter Password'
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                    {role == 'admin' ? (
                      <List>
                        <ListItem>
                          <Grid container spacing={4}>
                            <Grid item>
                              <FormControl
                                variant='outlined'
                                className={classes.grades}
                              >
                                <InputLabel
                                  shrink
                                  style={{
                                    backgroundColor: '#fafafa',
                                    padding: '0px 5px',
                                    marginLeft: 10,
                                  }}
                                >
                                  {'Admin Role'}
                                </InputLabel>
                                <Field
                                  name='admin_role'
                                  component={Select}
                                  className={classes.formControl}
                                  label='Please Select Admin Role'
                                  variant='outlined'
                                  helperText='Please Select Admin Role'
                                >
                                  {adminRolesList.map((adminRole) => (
                                    <MenuItem value={adminRole}>
                                      {adminRole}
                                    </MenuItem>
                                  ))}
                                </Field>
                                <FormHelperText style={{ marginLeft: 25 }}>
                                  Please Select Admin Role
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl
                                variant='outlined'
                                className={classes.grades}
                              >
                                <InputLabel
                                  shrink
                                  style={{
                                    backgroundColor: '#fafafa',
                                    padding: '0px 5px',
                                    marginLeft: 10,
                                  }}
                                >
                                  {'Is Academic Counsellor?'}
                                </InputLabel>
                                <Field
                                  name='is_academic_counsellor'
                                  component={Select}
                                  className={classes.formControl}
                                  label='Show in Academic Counsellor List'
                                  variant='outlined'
                                  helperText='Show in Academic Counsellor List'
                                >
                                  <MenuItem value={true}>Yes</MenuItem>
                                  <MenuItem value={false}>No</MenuItem>
                                </Field>
                                <FormHelperText style={{ marginLeft: 25 }}>
                                  Show in Academic Counsellor List
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    ) : null}
                    <List>
                      <ListItem>
                        <Grid container spacing={4}>
                          <Grid item>
                            <Field
                              name='city_name'
                              type='text'
                              component={TextField}
                              label='City'
                              className={classes.formControl}
                              variant='outlined'
                              placeholder='City'
                              helperText='Please Enter City'
                            />
                          </Grid>

                          {role === 'teacher' ? (
                            <Grid item>
                              <S3UploadButton
                                bucketName='lq-live'
                                fileType='image'
                                title='Upload Profile Picture'
                                dirName='teacher'
                                fileName={
                                  moment().format('YYYYMMDDHHmmss') +
                                  '_' +
                                  FormValues.parent_details.name
                                }
                                setField={(value) =>
                                  formProps.setFieldValue('avatar', value)
                                }
                                disabled={!FormValues.parent_details.name}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </ListItem>
                    </List>
                    {role == 'teacher' ? (
                      <List>
                        <ListItem>
                          <Grid container spacing={4}>
                            <Grid item>
                              <Field
                                name='parent_details.description'
                                type='text'
                                component={TextField}
                                className={classes.formControl}
                                label={'Description'}
                                variant='outlined'
                                placeholder={'Teacher Description'}
                                helperText={'Please Enter Description'}
                                multiline
                                rows={4}
                              />
                            </Grid>
                            <Grid item>
                              {FormValues.avatar ? (
                                <div
                                  style={{ width: 200, position: 'relative' }}
                                >
                                  <img
                                    src={FormValues.avatar}
                                    style={{ width: '100%' }}
                                  />
                                  <IconButton
                                    variant='contained'
                                    color='primary'
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                    }}
                                    onClick={() =>
                                      formProps.setFieldValue('avatar', null)
                                    }
                                  >
                                    <Icon>close</Icon>
                                  </IconButton>
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className={classes.footer}>
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  style={{ alignSelf: 'center' }}
                >
                  {'SAVE'}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const styles = makeStyles((theme) => ({
  fullWidth: { gridColumn: 'span 3' },
  lables: {},
  formComponent: {
    width: '100%',
    padding: 15,
  },
  grades: {
    minWidth: 200,
    maxWidth: 300,
  },
  formControl: {
    minWidth: 210,
    maxWidth: 400,
    marginLeft: theme.spacing(2),
  },
  formControlParentForm: {
    minWidth: 100,
  },
  longText: {
    minWidth: 400,
    marginLeft: -16,
  },
  twoForms: {
    display: 'flex',
    flexDirection: 'row',
  },

  formBox: { width: '100%', height: '100%' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
  parentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  parentFormComponent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  parentContentBox: {
    display: 'flex',
    gap: '16px',
    margin: '0px',
    width: '100%',
  },
  gridBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '16px',
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
  },
  formTitle: {
    fontSize: '22px',
    fontWeight: 600,
  },
  headingBox: {
    display: 'flex',
    gap: '16px',
  },

  footer: {
    position: 'sticky',
    zIndex: 5,
    padding: '20px',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'flex-end',
    borderTop: '0.3px solid #ccc',
  },
}));

function mapStateToProps(state) {
  const classMap = {};
  // state.lists && state.lists.classList.forEach(d => (classMap[d.value] = d));
  /*   const countryCodeList = Object.keys(state.lists.countryMap).map(
        key => state.lists.countryMap[key],
    ); */
  return {
    classList: state.lists.classList,
    user: state.user,
    classMap,
    lists: state.lists,
  };
}

export default withSnackbar(
  connect(mapStateToProps, actions, null)(CreateUserForm)
);
