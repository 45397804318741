import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import {
  CircularProgress,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Table,
  TableBody,
  TableHead,
} from '@material-ui/core';

import moment from 'moment';

function InstallmentDetails(props) {
  const { setShowModal, studentId, orderDetails, btnLoader } = props;

  return btnLoader ? (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  ) : !orderDetails?.length ? (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
      No Data
    </div>
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Edit</TableCell>
          {Object.keys(orderDetails?.[0] || {}).map((header) =>
            header === 'id' ? null : (
              <TableCell>
                {header.charAt(0).toUpperCase() +
                  header.replaceAll('_', ' ').slice(1)}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {orderDetails?.map((packageData) => (
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton>
                  <Tooltip title='Update'>
                    <Icon
                      className='iconButton'
                      onClick={() =>
                        setShowModal({
                          type: 'edit',
                          studentId,
                          installmentId: packageData?.id,
                        })
                      }
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </IconButton>
              </div>
            </TableCell>
            {Object.keys(orderDetails[0]).map((header) =>
              header === 'id' ? null : header === 'created_at' ? (
                <TableCell>
                  {moment(packageData['created_at']).format('YYYY-MM-DD')}
                </TableCell>
              ) : (
                <TableCell>{packageData[header] || '-'}</TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default InstallmentDetails;
