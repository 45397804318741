import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
} from '@material-ui/core';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '60vh',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: 'fit-content',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

function DetailDisplayModal(props) {
  const classes = userStyles();

  return (
    <div className={classes.Main}>
      <Modal
        open={props.openModal}
        onClose={() => {
          props.closeModal();
        }}
      >
        <div
          className={classes.Modal}
          style={props.maxHeight ? { maxHeight: props.maxHeight } : {}}
        >
          <div className={classes.header}>
            <div style={{ margin: 'auto 0px', fontSize: 20, fontWeight: 500 }}>
              {props.headerText || ''}
            </div>
            <div>
              <IconButton onClick={props.closeModal}>
                <Tooltip title='Close Modal'>
                  <Icon className='iconButton'>close</Icon>
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <div style={{ ...props.style }}>{props.displayData || ''}</div>
        </div>
      </Modal>
    </div>
  );
}

export default DetailDisplayModal;
