import React from 'react';
import Select, { Creatable } from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { titleCase } from 'title-case';
import NoSsr from '@material-ui/core/NoSsr';
import Components, { styles } from './Components';

const SingleSelect = (props) => {
  const {
    classes,
    className,
    name,
    onChange,
    selectList,
    value,
    theme,
    onBlur,
    defaultValue,
    isDisabled,
    isClearable,
    label,
  } = props;

  const selectStyles = {
    zIndex: 10,

    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      position: 'relative',
      cursor: 'pointer',
      '& input': {
        font: 'inherit',
        overflowY: 'scroll',
      },
    }),
  };
  const def = () => {};
  return (
    <NoSsr>
      <Creatable
        classes={classes}
        className={className}
        styles={selectStyles}
        textFieldProps={{
          label: titleCase(label || name),
          InputLabelProps: {
            shrink: true,
          },
        }}
        options={selectList}
        components={Components}
        value={value}
        onChange={onChange}
        onBlur={onBlur || def}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isClearable={isClearable}
        // formatCreateLabel={(value) => value}
      />
    </NoSsr>
  );
};

export default withStyles(styles, { withTheme: true })(SingleSelect);
